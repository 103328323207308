import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { PAGE_HOME } from '../constants/router';
import { isLoggedIn } from '../services/auth';
import SEO from '../views/components/SEO';
import BreadcrumbLayout from '../views/layouts/BreadcrumbLayout';
import MainLayout from '../views/layouts/MainLayout';

const NotFoundPage = ({ location }) => {
    const renderContent = () => (
        <>
            <SEO title='404' description='404' location={location} pageType='404' />
            <h1>404</h1>
            <p>Not found</p>
            <Link to={PAGE_HOME}>Page 2</Link>
        </>
    );
    return isLoggedIn() ? (
        <MainLayout>
            <BreadcrumbLayout>{renderContent()}</BreadcrumbLayout>
        </MainLayout>
    ) : (
        renderContent()
    );
};

NotFoundPage.propTypes = {
    location: PropTypes.object.isRequired,
};

export default NotFoundPage;
